export const episodes = [
    {
        title: 'The Storm',
        length: '6',
        description: `A couple decides to stay home today and face the incoming hurricane.`,
        youtubeUrl: 'https://www.youtube.com/embed/Fm1kRRMO3CA?rel=0&modestbranding=1',
        downloadLink: 'https://fanlink.to/guenet-thestorm',
    },
    {
        title: 'Siren',
        length: '5',
        description: `A man's ecstatic contemplation of a woman's voice leads him to doubt his own existence.`,
        youtubeUrl: 'https://www.youtube.com/embed/jCH9cbR6CXE?rel=0&modestbranding=1',
        downloadLink: 'https://fanlink.to/guenet-siren',
        modalContents: <iframe title="siren-video"
            width={560} height={315} frameBorder={0} allowFullScreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            src="https://www.youtube.com/embed/jCH9cbR6CXE?rel=0&autoplay=1"></iframe>,
        extra: <a href="https://www.amazon.com/dp/B0B723YWWX" target="_blank" rel="noreferrer"
            style={{fontSize: 12, marginTop: 5}}>
            Get the BOOK!
        </a>,
    },
    {
        title: 'The River',
        length: '5',
        description: `The last drop of the last river on earth evaporates.`,
        youtubeUrl: 'https://www.youtube.com/embed/a8MmPDqwNJc?rel=0&modestbranding=1',
        downloadLink: 'https://fanlink.to/guenet-theriver'
    },
    {
        title: "Jean's Memory",
        length: '7',
        description: `Jean grapples with the sense that her mind and memory are getting out of focus in her winter years.`,
        youtubeUrl: 'https://www.youtube.com/embed/eOg4X92OMaA?rel=0&modestbranding=1',
        downloadLink: 'https://fanlink.to/jeansmemory',
    },
    {
        title: "Innocent",
        length: '4',
        description: `Deprived of his freedom for most of his life, he's left to define his time on earth from within.`,
        youtubeUrl: 'https://www.youtube.com/embed/EV7pifgfAWc?rel=0&modestbranding=1',
        downloadLink: 'https://fanlink.to/guenet-innocent',
    },
    {
        title: "Stranded",
        length: '5',
        description: `A family road trip across the ocean, on a paper boat.`,
        youtubeUrl: 'https://www.youtube.com/embed/YvNEUUy2bKU?rel=0&modestbranding=1',
        downloadLink: 'https://fanlink.to/stranded-guenet',
    },
    {
        title: "Army Dreamers",
        length: '4',
        description: `A father mourns his soldier son.`,
        youtubeUrl: 'https://www.youtube.com/embed/6GfCjVT3jzc?rel=0&modestbranding=1',
        downloadLink: 'https://antoineguenet.hearnow.com/army-dreamers',
    },
];
export const moreLikeThisTexts = [
    'Sorry buddy, that\'s not a thing.',
    'No way Jose.',
    'If you find it, I want to know... #LikeSlowBurn',
    'no no no no no no no no no no no no no no no no no no no no no',
    'How about we stay home today?',
    'Where do you think you\'re going?',
];
