import React from 'react';
import ReactGA from 'react-ga';
import { CardGroup, Container } from 'react-bootstrap';
import Album from './Album';
import { albums } from './data';

interface albumsProps {
    setIsDark: Function;
}
export default function Albums ({ setIsDark }: albumsProps) {
	React.useEffect(() => ReactGA.pageview('/albums'), []);
    React.useEffect(() => setIsDark(false), [setIsDark]);
    const cards = albums.map(({artist, album, year, label, image, embedSource, embedUrl, shops}) => (
        <Album artist={artist} album={album} year={year} label={label}
            image={image} embedSource={embedSource} embedUrl={embedUrl}
            shops={shops}/>
    ));
    const decks = [];
    while (cards.length) {
        const deck = (
            <CardGroup className="card-deck mb-md-3" key={`deck-${decks.length}`}>
                {cards.shift()}
                {cards.length ? cards.shift() : null}
                {cards.length ? cards.shift() : null}
            </CardGroup>
        );
        decks.push(deck);
    }
    return (
        <Container>
            <h1>Discography</h1>
            {decks}
        </Container>
    );
}
