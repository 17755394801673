import React from 'react';
import Home from '../Home/Home';
import NavBar from '../NavBar/NavBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import Social from '../Social/Social';
import Timeline from '../Timeline/Timeline';
import Albums from '../Albums/Albums';
import AiArt from '../AiArt/AiArt';
import Siren from '../Siren/Siren';
import Series from '../Series/Series';

export default function App() {
	const [isDark, setIsDark] = React.useState(false);
    return (
		<>
			<BrowserRouter>
				<NavBar isDark={isDark}/>
				<main className={isDark ? 'dark-mode' : 'light-mode'}>
					<Switch>
						<Route path="/social">
							<Social setIsDark={setIsDark}/>
						</Route>
						<Route path="/timeline">
							<Timeline setIsDark={setIsDark}/>
						</Route>
						<Route path="/albums">
							<Albums setIsDark={setIsDark}/>
						</Route>
						<Route path="/ai">
							<AiArt setIsDark={setIsDark}/>
						</Route>
						<Route path={["/slowburn", "/series"]}>
							<Series setIsDark={setIsDark}/>
						</Route>
						<Route path="/siren">
							<Siren setIsDark={setIsDark}/>
						</Route>
						<Route path="/">
							<Home setIsDark={setIsDark}/>
						</Route>
					</Switch>
				</main>
			</BrowserRouter>
		</>
  );
}
