import React from 'react';
import { Card } from 'react-bootstrap';
import { EmbedSource, AlbumData } from './types';

export default function Album ({ artist, album, year, label, image, embedSource, embedUrl, shops }: AlbumData) {
    return (
        <Card>
            <Card.Img src={image} alt={`${artist} - ${album} (${year})`} />
            <Card.Header>
                <Card.Title>{album}</Card.Title>
                <Card.Subtitle className="mb-2">{artist} ({year})</Card.Subtitle>
                <Card.Subtitle className="mb-2 text-muted"><small>{label}</small></Card.Subtitle>
            </Card.Header>
            <Card.Text>
                {
                    embedSource === EmbedSource.SPOTIFY
                        ? <iframe title={`${embedSource}-${album}`} src={embedUrl as string}
                            width="100%" height={300} frameBorder={0} ></iframe>
                        : (
                            embedSource === EmbedSource.BANDCAMP
                                ? <iframe title={`${embedSource}-${album}`}
                                    className="embed-responsive-item"
                                    style={{ border: 0, width: '100%', height: 300 }}
                                    src={embedUrl as string} seamless>{
                                        'Bandcamp' in shops
                                            ? <a href={shops.Bandcamp}>{album} by {artist}</a>
                                            : ''
                                    }</iframe>
                                : embedUrl as JSX.Element
                        )
                }
            </Card.Text>
            <Card.Body>
                {
                    Object.keys(shops).map(shop => (
                        <Card.Link href={shops[shop]} key={`${album}-shop-${shop}`}>
                            <small>{shop}</small>
                        </Card.Link>
                    ))
                }
            </Card.Body>
        </Card>
    )
}
