import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {TwitterTimelineEmbed} from 'react-twitter-embed';
import ReactGA from 'react-ga';
import './Social.css';

const TWITTER_HANDLE = 'antoine_guenet';

interface newsProps {
    setIsDark: Function;
}

export default function Social ({ setIsDark }: newsProps) {
	React.useEffect(() => ReactGA.pageview('/news'), []);
    React.useEffect(() => setIsDark(false), [setIsDark]);
    return (
        <Container id="social-media">
            <Row>
                <Col md={12} id="twitter">
                    <TwitterTimelineEmbed sourceType="profile"
                        screenName={TWITTER_HANDLE}
                        options={{height: '100%'}}/>
                </Col>
            </Row>
        </Container>
    );
}
