import React from 'react';
import ReactGA from 'react-ga';
import { Container, Dropdown } from 'react-bootstrap';
import './Series.css';
import Episode from './Episode';
import { episodes, moreLikeThisTexts } from './data';


let moreLikeThisShuffle = [...moreLikeThisTexts];
let lastUsedIndex: number|undefined;
const getRandomTextIndex = () => {
    if (lastUsedIndex !== undefined) {
        const lastUsedText = moreLikeThisShuffle[lastUsedIndex];
        moreLikeThisShuffle.splice(lastUsedIndex, 1);
        if (!moreLikeThisShuffle.length) {
            moreLikeThisShuffle = [...moreLikeThisTexts].filter(text => text !== lastUsedText);
        }
        lastUsedIndex = undefined;
    }
    const index = Math.round(Math.random() * (Math.max(0, moreLikeThisShuffle.length - 1)));
    lastUsedIndex = index;
    return index;
}

interface seriesProps {
    setIsDark: Function;
}

export default function Series({ setIsDark }: seriesProps) {
	React.useEffect(() => ReactGA.pageview('/slowburn'), []);
    const moreLikeThisRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => setIsDark(true), [setIsDark]);
    const [moreLikeThis, setMoreLikeThis] = React.useState(false);
    const [textIndex, setTextIndex] = React.useState<undefined|number>();
    React.useEffect(() => {
        if (textIndex !== undefined) {
            setMoreLikeThis(true);
        }
    }, [textIndex]);
    React.useEffect(() => {
        if (moreLikeThis === true) {
            setTimeout(() => {
                moreLikeThisRef?.current?.classList.toggle('show', true);
                setTimeout(() => moreLikeThisRef?.current?.classList.remove('show'), 1100);
            }, 100);
            setTimeout(() => setMoreLikeThis(false), 2200);
        }
    }, [moreLikeThis]);
    return <>
        <div id="more-like-this" ref={moreLikeThisRef} className={moreLikeThis ? '' : 'd-none'}><p>{moreLikeThisShuffle[textIndex || 0]}</p></div>
        <div id="series">
            <Container><h1>Slow Burn</h1></Container>
            <Container id="series-topbar">
                <div className="tab active title-text">Episodes</div>
                <div className="tab title-text" onClick={() => setTextIndex(getRandomTextIndex())}>More like this</div>
            </Container>
            <Container id="season">
                <Dropdown>
                    <Dropdown.Toggle className="title-text">Season 1</Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item>Season 1</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Container>
            <div id="episodes">
                {episodes.map((episode, i) => (
                    <Episode key={`episode_${i + 1}`} number={i + 1}
                        title={episode.title} length={episode.length}
                        description={episode.description} youtubeUrl={episode.youtubeUrl}
                        downloadLink={episode.downloadLink} extra={episode.extra} />
                ))}
            </div>
        </div>
    </>;
}
