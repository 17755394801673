import React from 'react';
import {events, title} from './data';
import Timeline from 'timelinejs-react';
import ReactGA from 'react-ga';
import { Container } from 'react-bootstrap';

interface tlProps {
    setIsDark: Function;
}

function TL ({ setIsDark }: tlProps) {
    React.useEffect(() => ReactGA.pageview('/timeline'), []);
    React.useEffect(() => setIsDark(false), [setIsDark]);
    return (
        <Container>
            <h1>Timeline</h1>
            <Timeline target={<div className="timeline" style={{width: '100%', height: 500}}/>}
                events={events} title={title}/>
        </Container>
    );
}

export default TL;
