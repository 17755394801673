import React from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { Card, CardGroup, Carousel, Container, Modal } from 'react-bootstrap';
import billionaires from './images/billionaires/cover.png';
import bezos1 from './images/billionaires/bezos1.png';
import bezos2 from './images/billionaires/bezos2.png';
import zuckerberg1 from './images/billionaires/zuckerberg1.png';
import zuckerberg2 from './images/billionaires/zuckerberg2.png';
import musk1 from './images/billionaires/musk1.png';
import musk2 from './images/billionaires/musk2.png';
import murdoch3 from './images/billionaires/murdoch3.png';
import murdoch4 from './images/billionaires/murdoch4.png';
import murdoch5 from './images/billionaires/murdoch5.png';
import trees from './images/trees/cover.png';
import tree1 from './images/trees/tree1.png';
import tree2 from './images/trees/tree2.png';
import tree3 from './images/trees/tree3.png';
import tree4 from './images/trees/tree4.png';
import tree5 from './images/trees/tree5.png';
import tree6 from './images/trees/tree6.png';
import tree7 from './images/trees/tree7.png';
import tree8 from './images/trees/tree8.png';
import tree9 from './images/trees/tree9.png';
import tree10 from './images/trees/tree10.png';
import tree11 from './images/trees/tree11.png';
import tree12 from './images/trees/tree12.png';
import tree13 from './images/trees/tree13.png';
import tree14 from './images/trees/tree14.png';
import tree15 from './images/trees/tree15.png';
import tree16 from './images/trees/tree16.png';
import tree17 from './images/trees/tree17.png';
import tree18 from './images/trees/tree18.png';
import tree19 from './images/trees/tree19.png';
import tree20 from './images/trees/tree20.png';
import tree21 from './images/trees/tree21.png';
import tree22 from './images/trees/tree22.png';
import tree23 from './images/trees/tree23.png';
import tree24 from './images/trees/tree24.png';


interface Collection {
    name: string;
    cover: string;
    items: string[];
    source: string;
    description?: string;
}
const COLLECTIONS: Collection[] = [
    { name: 'The billionaires', cover: billionaires, source: 'Stable Diffusion', items: [
        zuckerberg1, bezos1, musk1, murdoch3, zuckerberg2, bezos2, musk2, murdoch4, murdoch5,
    ] },
    { name: 'Research for "The River": tree people', cover: trees, source: 'Stable Diffusion', items: [
        tree1, tree2, tree3, tree4, tree5, tree6, tree7, tree8, tree9, tree10,
        tree11, tree12, tree13, tree14, tree15, tree16, tree17, tree18, tree19,
        tree20, tree21, tree22, tree23, tree24,
    ]},
];
const amazonBookUrl = "https://www.amazon.com/Siren-Antoine-Guenet/dp/B0B723YWWX/ref=sr_1_2?crid=29MX1QOEYH37B&keywords=siren+guenet&qid=1660415362&sprefix=siren+guenet%2Caps%2C161&sr=8-2";

interface aiProps {
    setIsDark: Function;
}

function ArtCollection ({ name, items }: {name: string, items: string[]}) {
    const [activeIndex, setActiveIndex] = React.useState(0);
    const goBack = () => {
        setActiveIndex((activeIndex - 1) < 0 ? 0 : (activeIndex - 1));
        if ((activeIndex - 1) < 0) {
            setActiveIndex(items.length - 1);  // Allow carousel to loop
        } else {
            setActiveIndex(activeIndex - 1);
        }
    };
    const goNext = () => {
        if ((activeIndex + 1) < items.length) {
            setActiveIndex(activeIndex + 1);
        } else {
            setActiveIndex(0);  // Allow carousel to loop
        }
    };
    function useKeyPress() {
        const upHandler = (ev: KeyboardEvent) => {
            if (ev.key === 'ArrowLeft') {
                goBack();
            }
            if (ev.key === 'ArrowRight') {
                goNext();
            }
        };
        // Add event listeners
        React.useEffect(() => {
          window.addEventListener('keyup', upHandler);
          // Remove event listeners on cleanup
          return () => {
            window.removeEventListener('keyup', upHandler);
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [activeIndex]);
    }
    useKeyPress();
    const onSelect = (selectedIndex: number) => {
        setActiveIndex(selectedIndex);
    };
    return <>
        <Carousel variant="dark" slide={false} controls={true} keyboard={false}
            activeIndex={activeIndex} onSelect={onSelect} indicators={false}
            interval={null}>
            {items.map((item, index) => (
                <Carousel.Item>
                    <img src={item} alt={`${name} #${index + 1}`}
                        className="d-block mx-auto"
                        style={{maxWidth: '100%', maxHeight: '70vh'}}/>
                </Carousel.Item>
            ))}
        </Carousel>
        <p style={{textAlign: 'center'}} className="text-muted mt-1 mb-0">
            <small>{activeIndex + 1}/{items.length}</small>
        </p>
    </>
}

function AiArt ({ setIsDark }: aiProps) {
    React.useEffect(() => ReactGA.pageview('/ai'), []);
    React.useEffect(() => setIsDark(false), [setIsDark]);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [modalContents, setModalContents] = React.useState(<></>);
    const openModal = (contents: JSX.Element) => {
        setModalContents(contents);
        setIsModalOpen(true);
    };
    const cards = COLLECTIONS.map(({name, cover, items, source, description }) => (
        <Card onClick={() => openModal(
            <>
                <Modal.Header closeButton>
                    <Modal.Title>{name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ArtCollection name={name} items={items}/>
                </Modal.Body>
            </>
        )}>
            <Card.Img src={cover} alt={name}
                style={{maxHeight: '40vw', width: '100%', objectFit: 'cover', height: '100%'}}/>
            <Card.Header>
                <Card.Title>{name}</Card.Title>
                <Card.Subtitle className="text-muted">Made with {source}.</Card.Subtitle>
                {description && <Card.Subtitle>{description}</Card.Subtitle>}
            </Card.Header>
        </Card>
    ));
    const decks = [];
    while (cards.length) {
        const deck = (
            <CardGroup className="card-deck mb-md-3" key={`deck-${decks.length}`}>
                {cards.shift()}
                {cards.length ? cards.shift() : null}
                {cards.length ? cards.shift() : null}
            </CardGroup>
        );
        decks.push(deck);
    }
    return (
        <Container>
            <h1>AI Art</h1>
            <p>Always interested in technology and in art, I find AI-generated
                art to be a fascinating playing field for creativity. Below you
                can find some of the art I generated using AI.<br/>
                You can also find my <a href={amazonBookUrl} target="_blank"
                rel="noreferrer">book on Amazon</a> or look at it <Link
                    to="/siren">here</Link> while listening to the song it was
                made to accompany.</p>
            {decks}
            <Modal show={isModalOpen} size='lg'
                onHide={() => setIsModalOpen(false)}>{modalContents}</Modal>
        </Container>
    );
}

export default AiArt;
