import React from 'react';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Container, Row } from 'react-bootstrap';
import './Series.css';

interface episodeProps {
    number: number;
    youtubeUrl: string;
    title: string;
    length: string;
    downloadLink: string;
    description: string;
    extra?: JSX.Element;
};

export default function Episode({ number, youtubeUrl, title, length, downloadLink, description, extra }: episodeProps) {
    const [showModal, setShowModal] = React.useState(false);
    return (
        <Container key={`episode_${number}`} className="episode">
            <Row>
                <Col xs={10} onClick={() => setShowModal(!showModal)}>
                    <Row className="episode-clickable">
                        <Col className="episode-video">
                            <iframe className="embed-responsive-item"
                                src={youtubeUrl}
                                title="YouTube video player" frameBorder={0}
                                allow="accelerometer; autoplay; clipboard-write;
                                    encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen>
                            </iframe>
                        </Col>
                        <Col className="episode-title-box">
                            <div className="episode-title title-text">{number}. {title}</div>
                            <div className="time normal-text">{length}m</div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={2} className="episode-download">
                    <a href={downloadLink} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faArrowDown} className="episode-download"/>
                    </a>
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="episode-description normal-text">{description}</Col>
                {extra ? <div className="episode-extra">{extra}</div> : null}
            </Row>
        </Container>
    );
}
