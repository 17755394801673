import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import HTMLFlipBook from 'react-pageflip';
import { ResponsiveFontSize } from '@allwqre/react-responsive-font-size';
import './SirenBook.css';
import cover from './images/cover.png';
import image_1_instru from './images/1-instru.png';
import image_2_instru from './images/2-instru.png';
import image_3_instru from './images/3-instru.png';
import image_4_instru from './images/4-instru.png';
import image_5_taught from './images/5-taught.png';
import image_6_seeBlue from './images/6-seeblue.png';
import image_7_brew from './images/7-brew.png';
import image_8_head from './images/8-head.png';
import image_9_breathe from './images/9-breathe.png';
import image_10_hurts from './images/10-hurts.png';
import image_11_song from './images/11-song.png';
import image_12_burns from './images/12-burns.png';
import image_13_seethrough from './images/13-seethrough.png';
import image_14_blind from './images/14-blind.png';
import image_15_standby from './images/15-standby.png';
import image_16_need from './images/16-need.png';
import image_17_lead from './images/17-lead.png';
import image_18_lead from './images/18-lead.png';
import image_19_shades from './images/19-shades.png';
import image_20_black from './images/20-black.png';
import image_21_move from './images/21-move.png';
import image_22_twilight from './images/22-twilight.png';
import image_23_breathe from './images/23-breathe.png';
import image_24_song from './images/24-song.png';
import image_25_burns from './images/25-burns.png';
import image_26_burns from './images/26-burns.png';
import image_27_seethrough from './images/27-seethrough.png';
import image_28_blind from './images/28-blind.png';
import image_29_comfort from './images/29-comfort.png';
import image_30_need from './images/30-need.png';
import image_31_lead from './images/31-lead.png';
import image_32_lead from './images/32-lead.png';
import image_33_lead from './images/33-lead.png';
import image_34_lead from './images/34-lead.png';
import image_35_instru from './images/35-instru.png';

const pages = [
    {className: 'cover-page', type: 'image', content: cover, alt: 'Man swimming vertically down into the ocean.'},
    {className: 'image-page', type: 'image', content: image_1_instru, alt: ''},
    {className: 'image-page', type: 'image', content: image_2_instru, alt: ''},
    {className: 'image-page', type: 'image', content: image_3_instru, alt: ''},
    {className: 'image-page', type: 'image', content: image_4_instru, alt: ''},
    {className: 'image-page', type: 'image', content: image_5_taught, alt: ''},
    {className: 'text-page', type: 'text', content: 'She taught me what I see is not true.'},
    {className: 'text-page', type: 'text', content: 'All I can see is blue.'},
    {className: 'image-page', type: 'image', content: image_6_seeBlue, alt: ''},
    {className: 'image-page', type: 'image', content: image_7_brew, alt: ''},
    {className: 'text-page', type: 'text', content: 'She saved me from drowning in her brew.'},
    {className: 'text-page', type: 'text', content: 'By holding my head down.'},
    {className: 'image-page', type: 'image', content: image_8_head, alt: ''},
    {className: 'image-page', type: 'image', content: image_9_breathe, alt: ''},
    {className: 'text-page', type: 'text', content: 'Now I can breathe under water.'},
    {className: 'text-page', type: 'text', content: 'Though it hurts.'},
    {className: 'image-page', type: 'image', content: image_10_hurts, alt: ''},
    {className: 'image-page', type: 'image', content: image_11_song, alt: ''},
    {className: 'text-page', type: 'text', content: 'Her song was sweet and lovely.'},
    {className: 'text-page', type: 'text', content: 'How it burns.'},
    {className: 'image-page', type: 'image', content: image_12_burns, alt: ''},
    {className: 'image-page', type: 'image', content: image_13_seethrough, alt: ''},
    {className: 'text-page', type: 'text', content: 'You see through me.'},
    {className: 'text-page', type: 'text', content: 'While I can\'t see you.'},
    {className: 'image-page', type: 'image', content: image_14_blind, alt: ''},
    {className: 'image-page', type: 'image', content: image_15_standby, alt: ''},
    {className: 'text-page', type: 'text', content: 'You stand by me.'},
    {className: 'text-page', type: 'text', content: 'And I do need you.'},
    {className: 'image-page', type: 'image', content: image_16_need, alt: ''},
    {className: 'image-page', type: 'image', content: image_17_lead, alt: ''},
    {className: 'text-page', type: 'text', content: 'To lead me on.'},
    {className: 'text-page', type: 'text', content: 'Lead me on.'},
    {className: 'image-page', type: 'image', content: image_18_lead, alt: ''},
    {className: 'text-page', type: 'text', content: ''},
    {className: 'text-page', type: 'text', content: ''},
    {className: 'text-page', type: 'text', content: 'And all these shades of blue...'},
    {className: 'image-page', type: 'image', content: image_19_shades, alt: ''},
    {className: 'image-page', type: 'image', content: image_20_black, alt: ''},
    {className: 'text-page', type: 'text', content: '... turn to black...'},
    {className: 'text-page', type: 'text', content: '... as I move down...'},
    {className: 'image-page', type: 'image', content: image_21_move, alt: ''},
    {className: 'image-page', type: 'image', content: image_22_twilight, alt: ''},
    {className: 'text-page', type: 'text', content: '... out of the twilight zone.'},
    {className: 'text-page', type: 'text', content: 'Now I can breathe under water, though it hurts.'},
    {className: 'image-page', type: 'image', content: image_23_breathe, alt: ''},
    {className: 'image-page', type: 'image', content: image_24_song, alt: ''},
    {className: 'text-page', type: 'text', content: 'Her song was sweet and lovely.'},
    {className: 'text-page', type: 'text', content: 'How it burns.'},
    {className: 'image-page', type: 'image', content: image_25_burns, alt: ''},
    {className: 'image-page', type: 'image', content: image_26_burns, alt: ''},
    {className: 'text-page', type: 'text', content: 'How it burns.'},
    {className: 'text-page', type: 'text', content: 'You see through me.'},
    {className: 'image-page', type: 'image', content: image_27_seethrough, alt: ''},
    {className: 'image-page', type: 'image', content: image_28_blind, alt: ''},
    {className: 'text-page', type: 'text', content: 'While I can\'t see you.'},
    {className: 'text-page', type: 'text', content: 'You comfort me...'},
    {className: 'image-page', type: 'image', content: image_29_comfort, alt: ''},
    {className: 'image-page', type: 'image', content: image_30_need, alt: ''},
    {className: 'text-page', type: 'text', content: 'I do need you.'},
    {className: 'text-page', type: 'text', content: 'To lead me on.'},
    {className: 'image-page', type: 'image', content: image_31_lead, alt: ''},
    {className: 'image-page', type: 'image', content: image_32_lead, alt: ''},
    {className: 'text-page', type: 'text', content: 'Lead me on.'},
    {className: 'text-page', type: 'text', content: 'Lead me on.'},
    {className: 'image-page', type: 'image', content: image_33_lead, alt: ''},
    {className: 'image-page', type: 'image', content: image_34_lead, alt: ''},
    {className: 'text-page', type: 'text', content: 'Lead me on.'},
    {className: 'cover-page', type: 'image', content: image_35_instru, alt: ''},
];

interface sirenProps {
    style?: React.CSSProperties;
}

export default function SirenBook (props: sirenProps) {
    const style = props.style;
    const book = React.useRef(null);
    const wrapper = React.useRef(null);
    const [isFlipping, setIsFlipping] = React.useState(false);
    const onKeyDown = (ev: KeyboardEvent) => {
        if (ev.key === 'ArrowLeft') {
            if (book?.current) {
                const bookRef: any = book.current;
                const pageFlip: any = bookRef.pageFlip();
                pageFlip.flipPrev();
            }
        } else if (ev.key === 'ArrowRight') {
            if (book?.current) {
                const bookRef: any = book.current;
                const pageFlip: any = bookRef.pageFlip();
                pageFlip.flipNext();
            }
        }
    }
    React.useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        const onFullscreenError = (ev: any) => {console.error(ev)};
        window.addEventListener('fullscreenerror', onFullscreenError);

        return () => {
            window.removeEventListener('keydown', onKeyDown);
            window.removeEventListener('fullscreenerror', onFullscreenError);
        };
    }, []);
    const toggleFullscreen = () => {
        if (wrapper?.current) {
            const el = wrapper.current as any;
            // Script courtesy of Mozilla.
            const doc: any = window.document;

            const isFullScreen =
                doc.fullscreenElement ||
                doc.mozFullScreenElement ||
                doc.webkitFullscreenElement ||
                doc.msFullscreenElement;

            if (isFullScreen) {
                if (doc.exitFullscreen) {
                    doc.exitFullscreen();
                } else if (doc.mozCancelFullScreen) {
                    doc.mozCancelFullScreen();
                } else if (doc.webkitExitFullscreen) {
                    doc.webkitExitFullscreen();
                } else if (doc.msExitFullscreen) {
                    doc.msExitFullscreen();
                }
            } else {
                if (el.requestFullscreen) {
                    el.requestFullscreen();
                } else if ('mozRequestFullScreen' in el) {
                    (el as any).mozRequestFullScreen();
                } else if ('webkitRequestFullScreen' in el) {
                    (el as any).webkitRequestFullScreen();
                } else if ('msRequestFullscreen' in el) {
                    (el as any).msRequestFullscreen();
                }
            }
        }
        window.dispatchEvent(new Event('resize'));
    }

    return (
        <>
            <div id="siren-book" className={`${isFlipping ? 'flipping' : ''}`} style={style}>
                <button className='btn btn-light siren-fullscreen desktop d-none d-lg-block'
                    onClick={() => toggleFullscreen()}>
                    <FontAwesomeIcon icon={faExpand} />
                </button>
                <div id="siren-book-wrapper" ref={wrapper}>
                    <HTMLFlipBook
                        width={800} height={800} className='' style={{margin: '0 auto'}} startPage={0} size={'stretch'}
                        minWidth={0} maxWidth={0} minHeight={0} maxHeight={0} drawShadow={true}
                        flippingTime={1000} usePortrait={true} startZIndex={0} autoSize={true}
                        maxShadowOpacity={1} showCover={true} mobileScrollSupport={true}
                        clickEventForward={true} useMouseEvents={true} swipeDistance={30}
                        onChangeState={ev => {
                            if (ev.data === 'flipping') {
                                setIsFlipping(true);
                            } else if (ev.data === 'read') {
                                setIsFlipping(false);
                            }
                        }}
                        showPageCorners={true} disableFlipByClick={false} ref={book}>
                        {pages.map((page, index) => {
                            let content;
                            if (page.type === 'text') {
                                content = <ResponsiveFontSize ratio={0.08}>
                                    {page.content}
                                </ResponsiveFontSize>
                            } else if (page.type === 'image') {
                                content = <img src={page.content} alt={page.alt}/>
                            }
                            return <div className={page.className} data-density="hard" key={`page_${index}`}>{content}</div>;
                        })}
                    </HTMLFlipBook>
                </div>
            </div>
            <div className='container' style={{marginTop: 30, ...style}}>
                <div className='row'>
                    <div className='col' style={{textAlign: 'left', paddingLeft: 0}}>
                        <button className='btn btn-light' onClick={ev => {
                            if (book?.current) {
                                const bookRef: any = book.current;
                                const pageFlip: any = bookRef.pageFlip();
                                pageFlip.flip(0);
                                // little hack to fix a pageFlip bug:
                                setTimeout(() => pageFlip.turnToPage(0), 900);
                            }
                        }}><FontAwesomeIcon icon={faAngleDoubleLeft}/></button>
                        <button className='btn btn-light' onClick={ev => {
                            if (book?.current) {
                                const bookRef: any = book.current;
                                const pageFlip: any = bookRef.pageFlip();
                                pageFlip.flipPrev();
                            }
                        }}><FontAwesomeIcon icon={faAngleLeft}/></button>
                    </div>
                    <div className='col d-lg-none'>
                        <button className='btn btn-light siren-fullscreen mobile'
                            onClick={() => toggleFullscreen()}>
                            <FontAwesomeIcon icon={faExpand} />
                        </button>
                    </div>
                    <div className='col' style={{textAlign: 'right', paddingRight: 0}}>
                        <button className='btn btn-light' onClick={ev => {
                            if (book?.current) {
                                const bookRef: any = book.current;
                                const pageFlip: any = bookRef.pageFlip();
                                pageFlip.flipNext();
                            }
                        }}><FontAwesomeIcon icon={faAngleRight}/></button>
                    </div>
                </div>
            </div>
        </>
    );
}