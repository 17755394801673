type Artist = string;
type AlbumName = string;
type Year = number;
type Label = string;
type Image = string;
export enum EmbedSource {
    SPOTIFY = 'Spotify',
    BANDCAMP = 'Bandcamp',
    CUSTOM = 'Custom',
}
type EmbedInfo = string | JSX.Element;
type ShopInfo = Record<string, string>;
export interface AlbumData {
    artist: Artist;
    album: AlbumName;
    year: Year;
    label: Label;
    image: Image;
    embedSource: EmbedSource;
    embedUrl: EmbedInfo;
    shops: ShopInfo;
}
