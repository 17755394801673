import React from 'react';
import './Piano.css';
import C4 from './sounds/C4.mp3';
import Db4 from './sounds/Db4.mp3';
import D4 from './sounds/D4.mp3';
import Eb4 from './sounds/Eb4.mp3';
import E4 from './sounds/E4.mp3';
import F4 from './sounds/F4.mp3';
import Gb4 from './sounds/Gb4.mp3';
import G4 from './sounds/G4.mp3';
import Ab4 from './sounds/Ab4.mp3';
import A4 from './sounds/A4.mp3';
import Bb4 from './sounds/Bb4.mp3';
import B4 from './sounds/B4.mp3';
import { Link } from 'react-router-dom';
import { Row } from 'react-bootstrap';

// CSS Piano adapted from https://codepen.io/zastrow/pen/oDBki

const KEYS = [
    ['white-key', 'c', C4],
    // ['white-key', 'c', C4, 'Social', '/social'],
    ['black-key', 'cs', Db4],
    ['white-key', 'd', D4],
    ['black-key', 'ds', Eb4],
    ['white-key', 'e', E4, 'Timeline', '/timeline'],
    ['white-key', 'f', F4, 'Albums', '/albums'],
    ['black-key', 'fs', Gb4],
    ['white-key', 'g', G4],
    // ['white-key', 'g', G4, 'AI Art', '/ai'],
    ['black-key', 'gs', Ab4],
    ['white-key', 'a', A4, 'Slow Burn', '/slowburn'],
    ['black-key', 'as', Bb4],
    ['white-key', 'b', B4, 'Siren', '/siren'],
];
const KEY_WIDTH = 64;
enum Direction {
    LEFT = 'left',
    RIGHT = 'right',
};
function addKeys(nKeys: number, direction: Direction): JSX.Element[] {
    const keysToAdd = direction === Direction.LEFT ? [...KEYS].reverse() : [...KEYS];
    const keys = [];
    for (let index = 0; index < nKeys; index++) {
        const [keyType, key, sound] = keysToAdd[index % keysToAdd.length];
        keys.push(
            // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
            <a className={`piano-key ${keyType} ${key}`} key={key + index}
            onClick={() => new Audio(sound).play()}></a>
        );
    }
    return direction === Direction.LEFT ? keys.reverse() : keys;
}

export default function Piano () {
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    React.useEffect(() => {
        const onResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', onResize);
        return window.removeEventListener('resize', onResize);
    }, []);
    const baseKeysWidth = KEYS.length * KEY_WIDTH;
    const nKeysToAdd = Math.floor((windowWidth - baseKeysWidth) / KEY_WIDTH);

    return (
        <Row id="piano">
            {/* Version for big screens */}
            <div className="piano d-none d-md-block">
                {addKeys(Math.floor(nKeysToAdd / 2), Direction.LEFT)}
                {KEYS.map(([keyType, key, sound, linkName, url, icon], index) => {
                    if (linkName) {
                        if (url.startsWith('/')) {
                            return (
                                <Link to={url} className={`piano-key ${keyType} ${key} piano-link`}
                                    onClick={() => new Audio(sound).play()} key={key + index}>
                                    <div className="key-text">
                                        <span>{linkName}</span>
                                    </div>
                                    {icon && <div className="key-icon">
                                        <i className={icon}></i>
                                    </div>}
                                </Link>
                            );
                        } else {
                            return (
                                <a href={url} className={`piano-key ${keyType} ${key} piano-link`}
                                    onClick={() => new Audio(sound).play()} key={key + index}>
                                    <div className="key-text">
                                        <span>{linkName}</span>
                                    </div>
                                    <div className="key-icon">
                                        <i className={icon}></i>
                                    </div>
                                </a>
                            );
                        }
                    } else {
                        return (
                            // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
                            <a className={`piano-key ${keyType} ${key}`} key={key + index}
                                onClick={() => new Audio(sound).play()}></a>
                        );
                    }
                })}
                {addKeys(Math.floor(nKeysToAdd / 2), Direction.RIGHT)}
            </div>

            {/* Version for small screens */}
            <div className="piano d-md-none">
                <a href="/social" className="piano-key white-key c"><span>Social</span></a>
                <a href="/timeline" className="piano-key black-key cs"><span>Timeline</span></a>
                <a href="/albums" className="piano-key white-key d"><span>Albums</span></a>
                <a href="/ai" className="piano-key black-key ds"><span>AI Art</span></a>
                <a href="/timeline" className="piano-key white-key e"><span>Timeline</span></a>
                <a href="/slowburn" className="piano-key white-key f"><span>Slow Burn</span></a>
                <a href="/siren" className="piano-key back-key fs"><span>Siren</span></a>
            </div>

        </Row>
    );
}
