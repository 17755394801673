import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Mailchimp from 'react-mailchimp-form';

const NAV_ITEMS = [
    ['Home', '/'],
    // ['Social', '/social'],
    ['Timeline', '/timeline'],
    ['Albums', '/albums'],
    // ['AI Art', '/ai'],
    ['Slow Burn', '/slowburn'],
    ['Siren', '/siren'],
];
const MAILCHIMP_URL = 'https://antoineguenet.us11.list-manage.com/subscribe/post?u=ac1e51a23eadae73782362104&amp;id=d4a4a2a2ba&amp;f_id=00f598e0f0';

interface navbarProps {
    isDark: boolean;
}

export default function NavBar ({isDark}: navbarProps) {
    return (
        <Navbar collapseOnSelect variant={isDark ? 'dark' : 'light'} bg={isDark ? 'black' : 'light'} expand="lg"  sticky="top">
			<Container>
				<LinkContainer to="/">
                    <Navbar.Brand>Antoine Guenet</Navbar.Brand>
                </LinkContainer>
				<Navbar.Toggle aria-controls="navbarNav"/>
				<Navbar.Collapse id="navbarNav">
                    <Nav className="me-auto">
                        {NAV_ITEMS.map(([itemName, url]) => (
                            url.startsWith('/')
                                ? <LinkContainer key={url} to={url}><Nav.Link>
                                    {itemName}&nbsp;/
                                </Nav.Link></LinkContainer>
                                : <Nav.Link key={url} href={url}>
                                    {itemName}&nbsp;/
                                </Nav.Link>
                        ))}
                    </Nav>
                    {/* <Nav className="me-auto">
                        <Mailchimp action={MAILCHIMP_URL} fields={[{
                            name: 'EMAIL',
                            placeholder: 'Email',
                            type: 'email',
                            required: true,
                        }]} messages = {{
                            sending: "Sending...",
                            success: "Thank you for subscribing!",
                            error: "An unexpected internal error has occurred.",
                            empty: "Can't subscribe without an e-mail...",
                            duplicate: "Too many subscribe attempts for this email address. Sorry.",
                            button: "OK"
                        }} className="mailchimp-form"/><span style={{color: 'rgba(0,0,0,.55)'}}>&nbsp;to get invited...</span>
                    </Nav> */}
			  	</Navbar.Collapse>
			</Container>
		</Navbar>
    );
}
