import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Piano from '../Piano/Piano';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import './Home.css';

interface homeProps {
    setIsDark: Function;
}

const YOUTUBE_FEATURE_URL =  'https://www.youtube.com/embed/6GfCjVT3jzc?rel=0';

export default function Home({ setIsDark }: homeProps) {
	React.useEffect(() => ReactGA.pageview('/'), []);
    React.useEffect(() => setIsDark(false), [setIsDark]);
    return (
        <Container>
            <Piano/>
            <Container id="latest news">
                <h1 className="text-center mb-md-0 mt-md-2">
                    Latest release: Army Dreamers (Slow Burn, episode 7)
                </h1>
                <p className="mt-md-0 mb-md-2 text-center">
                    The latest episode of <strong><u><Link to="/slowburn">Slow Burn</Link></u></strong>,
                    a cover of Kate Bush's classic song.
                </p>
                <Row id="manual-news" style={{textAlign: 'center'}}>
                    <Col xs={12} className='responsive-youtube-embed'>
                        <iframe className="embed-responsive-item"
                            src={YOUTUBE_FEATURE_URL}
                            title="YouTube video player" frameBorder={0}
                            allow="accelerometer; autoplay; clipboard-write;
                                encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                        </iframe>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}
