import React from 'react';
import { AlbumData, EmbedSource } from './types';
import manyYears from './images/bb.png';
import intoTheHerd from './images/hrd.png';
import zappaJawaka from './images/jwk.png';
import playsHendrix from './images/mm.png';
import oak from './images/oak.png';
import silenceInWonderland from './images/shh.png';
import shtgn from './images/sht.png';
import afterTheExhibition from './images/two.png';
import phosphorescentDreams from './images/uz.png';
import rewind from './images/rew.png';
import intrusion from './images/int.png';
import thestorm from './images/storm.png';
import siren from '../Siren/images/cover.png';
import theriver from './images/river.png';
import jean from './images/jean.png';
import innocent from './images/innocent.jpeg';
import stranded from './images/stranded.jpeg';
import armydreamers from './images/armydreamers.jpg';

export const albums: AlbumData[] = [
    {
        artist: 'Antoine Guenet',
        album: "Army Dreamers",
        year: 2024,
        label: 'Self-released',
        image: armydreamers,
        embedSource: EmbedSource.SPOTIFY,
        embedUrl: 'https://embed.spotify.com/?uri=spotify%3Aalbum%3A7fqYqWcf59HKOL2dQEdg6O?si=00YJCI-FQzK88mkZdTxXpQ&theme=white',
        shops: {
            'All platforms': 'https://antoineguenet.hearnow.com/army-dreamers',
            Bandcamp: 'https://antoineguenet.bandcamp.com/track/army-dreamers-kate-bush-cover',
            Amazon: 'https://music.amazon.com/albums/B0D84C8HQG',
        },
    },
    {
        artist: 'Antoine Guenet',
        album: "Stranded",
        year: 2023,
        label: 'Self-released',
        image: stranded,
        embedSource: EmbedSource.SPOTIFY,
        embedUrl: 'https://embed.spotify.com/?uri=spotify%3Aalbum%3A4hWF3nAUoF4068ick4OSzH?si=00YJCI-FQzK88mkZdTxXpQ&theme=white',
        shops: {
            'All platforms': 'https://fanlink.to/stranded-guenet',
            Bandcamp: 'https://antoineguenet.bandcamp.com/track/stranded',
            Amazon: 'https://music.amazon.com/albums/B0CM4LXGQ3',
        },
    },
    {
        artist: 'Antoine Guenet',
        album: "Innocent",
        year: 2023,
        label: 'Self-released',
        image: innocent,
        embedSource: EmbedSource.SPOTIFY,
        embedUrl: 'https://embed.spotify.com/?uri=spotify%3Aalbum%3A3jEm227wxH4PT0VOwb4Boe?si=AO0qQcxmQWKflLZ_vftsIg&theme=white',
        shops: {
            'All platforms': 'https://fanlink.to/guenet-innocent',
            Bandcamp: 'https://antoineguenet.bandcamp.com/track/innocent',
            Amazon: 'https://music.amazon.com/albums/B0C2SYL1KD',
            iTunes: 'https://music.apple.com/be/album/innocent-single/1683159046',
        },
    },
    {
        artist: 'Antoine Guenet',
        album: "Jean's Memory",
        year: 2022,
        label: 'Self-released',
        image: jean,
        embedSource: EmbedSource.SPOTIFY,
        embedUrl: 'https://embed.spotify.com/?uri=spotify%3Aalbum%3A7zn5Flw0tj3szgb9sdgPgL?si=gtW4Qej1Sl-dZlspaNiw2A&theme=white',
        shops: {
            'All platforms': 'https://fanlink.to/jeansmemory',
            Bandcamp: 'https://antoineguenet.bandcamp.com/track/jeans-memory',
            Amazon: 'https://music.amazon.com/albums/B0BKLTX2HN',
            iTunes: 'https://music.apple.com/be/album/jeans-memory-single/1651699406',
        },
    },
    {
        artist: 'Antoine Guenet',
        album: 'The River',
        year: 2022,
        label: 'Self-released',
        image: theriver,
        embedSource: EmbedSource.SPOTIFY,
        embedUrl: 'https://embed.spotify.com/?uri=spotify%3Aalbum%3A5pUM6pjqGz1hg6k2PjofMS?si=gtW4Qej1Sl-dZlspaNiw2A&theme=white',
        shops: {
            'All platforms': 'https://fanlink.to/guenet-theriver',
            Bandcamp: 'https://antoineguenet.bandcamp.com/track/the-river',
            Amazon: 'https://music.amazon.com/albums/B0BDSLPL4Q',
            iTunes: 'https://music.apple.com/be/album/the-river-single/1646002305',
        },
    },
    {
        artist: 'Antoine Guenet',
        album: 'Siren',
        year: 2022,
        label: 'Self-released',
        image: siren,
        embedSource: EmbedSource.SPOTIFY,
        embedUrl: 'https://embed.spotify.com/?uri=spotify%3Aalbum%3A3dGcd0cKVLSQQS0FhtXy8W?si=gtW4Qej1Sl-dZlspaNiw2A&theme=white',
        shops: {
            'All platforms': 'https://fanlink.to/guenet-siren',
            BOOK: 'https://www.amazon.com/dp/B0B723YWWX',
            Bandcamp: 'https://antoineguenet.bandcamp.com/track/siren',
            Amazon: 'https://music.amazon.com/albums/B0B5HQKQK9',
            iTunes: 'https://music.apple.com/be/album/siren-single/1632912260',
        },
    },
    {
        artist: 'Antoine Guenet',
        album: 'The Storm',
        year: 2022,
        label: 'Self-released',
        image: thestorm,
        embedSource: EmbedSource.SPOTIFY,
        embedUrl: 'https://embed.spotify.com/?uri=spotify%3Aalbum%3A6YBE9c65UH7ckeAasBKZZb&theme=white',
        shops: {
            'All platforms': 'https://fanlink.to/guenet-thestorm',
            Bandcamp: 'https://antoineguenet.bandcamp.com/track/the-storm',
            Amazon: 'https://music.amazon.com/albums/B09TFFQ1HD',
            iTunes: 'https://music.apple.com/be/album/the-storm-single/1611748408',
        },
    },
    {
        artist: 'Machine Mass Sextet',
        album: 'Intrusion',
        year: 2021,
        label: 'Off Records',
        image: intrusion,
        embedSource: EmbedSource.SPOTIFY,
        embedUrl: 'https://embed.spotify.com/?uri=spotify%3Aalbum%3A2nwG0Xj9LS4xQJy8IqPL46&theme=white',
        shops: {
            Amazon: 'https://www.amazon.de/Intrusion-Machine-Mass-Sextet/dp/B08QFBMSJN',
            Off: 'http://off-recordlabel.blogspot.com/2021/02/ocd049-machine-mass-sextet-intrusion.html',
        },
    },
    {
        artist: 'Beautiful Badness',
        album: 'Rewind',
        year: 2020,
        label: 'Jo&Co (France)',
        image: rewind,
        embedSource: EmbedSource.SPOTIFY,
        embedUrl: 'https://embed.spotify.com/?uri=spotify%3Aalbum%3A38icrQ3ml1POYkbBTrj9kI&theme=white',
        shops: {
            Amazon: 'https://www.amazon.com/Rewind-Beautiful-Badness/dp/B08N6CLTCC',
            iTunes: 'https://music.apple.com/fr/album/rewind/1542994258',
        },
    },
    {
        artist: 'The Wrong Object',
        album: 'Into the Herd',
        year: 2019,
        label: 'Off Records',
        image: intoTheHerd,
        embedSource: EmbedSource.SPOTIFY,
        embedUrl: 'https://embed.spotify.com/?uri=spotify%3Aalbum%3A4sBecgAEIHFQrJdjXD2eUg&theme=white',
        shops: {
            Amazon: 'https://www.amazon.com/Into-Herd-Wrong-Object/dp/B07LGTXY5X',
            Off: 'http://off-recordlabel.blogspot.com/2019/02/ocd043-wrong-object-into-herd.html',
        },
    },
    {
        artist: 'The Gödel Codex',
        album: 'Oak',
        year: 2019,
        label: 'Off Records',
        image: oak,
        embedSource: EmbedSource.SPOTIFY,
        embedUrl: 'https://embed.spotify.com/?uri=spotify%3Aalbum%3A6S77JaAlQUQcopMRDkPpbA&theme=white',
        shops: {
            Amazon: 'https://www.amazon.com/Oak-G%C3%B6del-Codex/dp/B07J9Q69P3',
            Off: 'http://off-recordlabel.blogspot.com/2019/01/ocd041-godel-codex-oak.html',
        },
    },
    {
        artist: 'The Wrong Object',
        album: 'Zappa Jawaka',
        year: 2018,
        label: 'Off Records',
        image: zappaJawaka,
        embedSource: EmbedSource.SPOTIFY,
        embedUrl: 'https://embed.spotify.com/?uri=spotify%3Aalbum%3A6iktlgIhVGbpkzNumrkfin&theme=white',
        shops: {
            Amazon: 'https://www.amazon.com/Zappa-Jawaka-Wrong-Object/dp/B07J35QCL5',
            iTunes: 'https://itunes.apple.com/us/album/zappa-jawaka/1438761029',
            Off: 'https://off-recordlabel.blogspot.com/2018/11/ocd042-wrong-object-zappa-jawaka.html',
        },
    },
    {
        artist: 'Machine Mass',
        album: 'Plays Hendrix',
        year: 2017,
        label: 'MoonJune Records (NY, USA)',
        image: playsHendrix,
        embedSource: EmbedSource.BANDCAMP,
        embedUrl: 'https://bandcamp.com/EmbeddedPlayer/album=1501088560/size=large/bgcol=ffffff/linkcol=0687f5/artwork=small/transparent=true/',
        shops: {
            Amazon: 'https://www.amazon.com/Plays-Hendrix-Machine-Mass/dp/B07285W84P',
            Bandcamp: 'https://thewrongobject.bandcamp.com/album/plays-hendrix',
        },
    },
    {
        artist: 'Beautiful Badness',
        album: 'Many Years EP',
        year: 2016,
        label: 'Jo&Co (France)',
        image: manyYears,
        embedSource: EmbedSource.SPOTIFY,
        embedUrl: 'https://open.spotify.com/embed?uri=spotify%3Aalbum%3A090uQdQcL0wAO9GIe7nC3n&theme=white',
        shops: {
            Amazon: 'https://www.amazon.com/Many-Years-Beautiful-Badness/dp/B01LZEJ95Z',
            iTunes: 'https://itunes.apple.com/us/album/many-years-ep/id1162638812',
        },
    },
    {
        artist: 'Univers Zéro',
        album: 'Phosphorescent Dreams',
        year: 2014,
        label: 'Arcangelo/DiskUnion (Japan) - ARC1313',
        image: phosphorescentDreams,
        embedSource: EmbedSource.SPOTIFY,
        embedUrl: 'https://open.spotify.com/embed?uri=spotify%3Aalbum%3A0zc7KErux208FBcMzNYHWG&theme=white',
        shops: {
            Amazon: 'https://www.amazon.com/Univers-Zero-Phosphorescent-Digipak-ARC-1313/dp/B00H72R55W',
        },
    },
    {
        artist: 'The Wrong Object',
        album: 'After the Exhibition',
        year: 2013,
        label: 'MoonJune Records (NY, USA) - MJR055',
        image: afterTheExhibition,
        embedSource: EmbedSource.BANDCAMP,
        embedUrl: 'https://bandcamp.com/EmbeddedPlayer/album=563618234/size=large/bgcol=ffffff/linkcol=0687f5/artwork=small/transparent=true/',
        shops: {
            Amazon: 'https://www.amazon.com/After-Exhibition-Wrong-Object/dp/B00CA4S24K',
            Bandcamp: 'https://thewrongobject.bandcamp.com/album/after-the-exhibition',
        },
    },
    {
        artist: 'SH.TG.N',
        album: 'SH.TG.N',
        year: 2012,
        label: 'MoonJune Records (NY, USA) - MJR046',
        image: shtgn,
        embedSource: EmbedSource.BANDCAMP,
        embedUrl: 'https://bandcamp.com/EmbeddedPlayer/album=1371942852/size=large/bgcol=ffffff/linkcol=0687f5/artwork=small/transparent=true/',
        shops: {
            Amazon: 'https://www.amazon.com/Sh-Tg-N/dp/B007U63Z5E',
            Bandcamp: 'https://antoineguenetsusanclynes.bandcamp.com/album/sh-tg-n',
        },
    },
    {
        artist:  '(S)hhh',
        album: 'Silence in Wonderland (det allra viktigaste)',
        year: 2008,
        label: 'Self-released',
        image: silenceInWonderland,
        embedSource: EmbedSource.CUSTOM,
        embedUrl: <React.Fragment>
            The album can be downloaded for free on Patreon.<br/>
            Enjoy :-)
            <br/><br/><br/>
        </React.Fragment>,
        shops: {
            'Download for free': 'https://www.patreon.com/posts/download-s-hhh-15514077',
        },
    },
];
