import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAmazon, faApple, faBandcamp, faDeezer, faItunes, faSpotify } from '@fortawesome/free-brands-svg-icons';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import ShareOnSocial from "react-share-on-social";
import SirenBook from './SirenBook';
import favicon from './images/cover.png';
import './Siren.css';
import ReactGA from 'react-ga';
import { Container } from 'react-bootstrap';

interface sirenProps {
    setIsDark: Function;
}

const amazonBookUsaUrl = "https://www.amazon.com/Siren-Antoine-Guenet/dp/B0B723YWWX/ref=sr_1_2?crid=29MX1QOEYH37B&keywords=siren+guenet&qid=1660415362&sprefix=siren+guenet%2Caps%2C161&sr=8-2";
const amazonBookFrUrl = "https://amzn.eu/d/9hp9FWJ";
const amazonBookNlUrl = "https://amzn.eu/d/94rK8Rp";

export default function Siren ({ setIsDark }: sirenProps) {
	React.useEffect(() => ReactGA.pageview('/siren'), []);
    React.useEffect(() => setIsDark(false), [setIsDark]);
    return (
        <Container>
            <h1>Siren</h1>
            <div id="siren" style={{textAlign: 'center'}}>
                <p className='text-muted'><small>Flip the pages of the book below while listening to Siren.
                The book follows the lyrics and instrumental parts of the song. The book is also available on <a
                    href={amazonBookUsaUrl} target="_blank" rel="noreferrer">Amazon</a> (for Europe, use Amazon <a href={amazonBookFrUrl} target="_blank" rel="noreferrer">France</a> or <a href={amazonBookNlUrl} target="_blank" rel="noreferrer">Netherlands</a>).</small></p>
                <iframe title="sirenBandcampEmbed"
                    src="https://bandcamp.com/EmbeddedPlayer/track=40093920/size=small/bgcol=ffffff/linkcol=0687f5/transparent=true/"
                    style={{border: 0, width: '100%', height: 42, maxWidth: 700}}
                    width='100%' seamless>
                    <a href="https://antoineguenet.bandcamp.com/track/siren">
                        Siren by Antoine Guenet
                    </a>
                </iframe>
                <div id="music-links">
                    <a href="https://open.spotify.com/album/3dGcd0cKVLSQQS0FhtXy8W" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faSpotify}/>
                    </a>
                    <a href="https://itunes.apple.com/WebObjects/MZStore.woa/wa/search?at=1000lM6c&term=antoine%20guenet&app=apple" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faApple}/>
                    </a>
                    <a href="https://open.spotify.com/album/3dGcd0cKVLSQQS0FhtXy8W" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faItunes}/>
                    </a>
                    <a href="https://music.amazon.com/albums/B0B5HQKQK9?tag=fndcmpgns-20" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faAmazon}/>
                    </a>
                    <a href="https://www.deezer.com/en/album/332196197" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faDeezer}/>
                    </a>
                    <a href="https://antoineguenet.bandcamp.com/track/siren" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faBandcamp}/>
                    </a>
                    <ShareOnSocial
                        link="https://www.antoineguenet.com/siren"
                        linkTitle="Antoine Guenet - Siren"
                        linkMetaDesc="Listen to Siren while looking at her."
                        linkFavicon={favicon}
                        noReferer
                        >
                        <button>
                            <FontAwesomeIcon icon={faShare}/>
                        </button>
                    </ShareOnSocial>
                </div>
                <SirenBook/>
                <p style={{marginTop: 30, textAlign: 'center'}} className='text-muted'>
                    Cover image by <a href="https://www.instagram.com/mooshieartshop/">Raine Guenet</a>.<br/>
                    All other images made with <a href="https://www.midjourney.com">MidJourney</a>.
                </p>
            </div>
        </Container>
    );
}
